import React from "react"
import { getNavigationProps } from "@components/Navigation/getNavigationProps"
import { MatchAPI } from "../src/apis/MatchAPI"
import { SEO } from "../src/types/next.js"
import InferNextPropsType from "infer-next-props-type"
import { CalciocomConfigsAPI } from "../src/apis/CalciocomConfigsAPI"
import * as NextConstants from "../src/constants/next"
import { CompetitionSeasonAPI } from "../src/apis/CompetitionSeasonAPI"
import { BookmakerBonusAPI } from "../src/apis/BookmakerBonusAPI"
import { simplifyNews } from "utils/news"
import { LocalizedMatchWithSimplifiedNews } from "../src/types/match"
import { simplifyBookmakerBonus } from "../src/utils/bookmakerBonus"
import { Col, Row } from "react-bootstrap"
import { MatchesDate } from "@components/Match/MatchesDate"
import { NewTitleBanner } from "@components/Banners/NewTitleBanner"
import homePredictions from "@assets/images/title-banner/home-predictions.png"
import homeTeamStats from "@assets/images/title-banner/home-team-stats.png"
import { CompetitionTeamParameterStandings } from "@components/Home/CompetitionTeamParameterStandings"
import { Newsletter } from "@components/Home/Newsletter"
import moment from "moment"
import { GroupAPI } from "../src/apis/GroupAPI"
import { GroupStandingsWithCompetition } from "@components/Competition/GroupStandingsWithCompetition"
import { VerticalPredictionMatchList } from "@components/Predictions/VerticalPredictionMatchList/VerticalPredictionMatchList"
import { simplifyMatches } from "types/matches/response.dto"
import { simplifyHomeData } from "utils/competition"

export default function Home({
  predictions,
  homeData,
  bettingData,
  teamParameterStandings,
  matches,
  teamStandings,
}: InferNextPropsType<typeof getStaticProps>) {
  return (
    <Row className={"home-page"}>
      <Col md={12} className="liveWidget">
        <div id="hs-content" className={"paddingFive matchesDateContainer"}>
          <MatchesDate viewAllButton matches={matches} reload={false} maxGroup={6} />
        </div>
      </Col>
      <NewTitleBanner
        noMarginBottom={true}
        title="Pronostici"
        icon={homePredictions}
        col={{ lg: 12 }}
        iconWidth={32}
        iconHeight={37}
      />
      <VerticalPredictionMatchList matchWithNewsList={predictions} />
      <NewTitleBanner title="Statistiche" icon={homeTeamStats} col={{ lg: 12 }} />
      <CompetitionTeamParameterStandings
        homeData={homeData}
        teamParameterStandings={teamParameterStandings}
        col={{ lg: 12 }}
      />
      {teamStandings && teamStandings.length > 0 && (
        <GroupStandingsWithCompetition
          headerTitle={"Statistiche per scommesse"}
          competitionCalciocomSlug={bettingData[0].competition.calciocomSlug}
          seasonCalciocomSlug={null}
          teamStandings={teamStandings}
          homeData={bettingData}
        />
      )}
      <Row className="marginZero">
        <Newsletter />
      </Row>
      <Row>
        <Col>
          <div className={"text-section"}>
            <div className={"text-div"}>
              <h1>Calcio.com: pronostici, statistiche e risultati in tempo reale</h1>
              <p style={{ marginTop: "1rem" }}>
                Su Calcio.com puoi trovare le migliori statistiche per sviluppare i tuoi pronostici scommesse Serie A,
                Serie B, Premier League, Champions league e i principali campionati europei. In più su calcio.com puoi
                consultare la lista dei migliori bonus scommesse dei principali operatori di gioco in Italia e
                consultare le guide scommesse per un’esperienza sicura e sempre informata. Su calcio.com è possibile
                trovare tutti i numeri del calcio: novità, statistiche, analisi e dati dello sport più bello del mondo.
                Il calcio non avrà più segreti per te grazie al database più aggiornato del web! Quello che ci distingue
                dagli altri magazine sportivi è l’offerta editoriale, 100% puro infotainment composto da dati,
                pronostici, analisi, approfondimenti, video, podcast e contenuti innovativi, prodotti da un team
                specializzato e attento a tutte le nuove tendenze.
              </p>
            </div>
          </div>
        </Col>
      </Row>
    </Row>
  )
}

export async function getStaticProps() {
  const now = moment()
  const [homeData, bettingData, bookmakerBonuses, matchPredictionOutput, navigationProps, matches] = await Promise.all([
    CalciocomConfigsAPI.getCalciocomConfigHomeData(),
    CalciocomConfigsAPI.getCalciocomConfigBettingData(),
    BookmakerBonusAPI.getHome(),
    MatchAPI.getPredictionsHome(),
    getNavigationProps(),
    MatchAPI.getPublicMatchesByDate(now.format("YYYY-MM-DD"), false, false, false, false),
  ])

  const currentHomeData = homeData[0]

  const [teamParameterStandings, teamStandings] = await Promise.all([
    CompetitionSeasonAPI.getTeamParameterStandings(currentHomeData.competitionSeason?.id || 1),
    GroupAPI.getTeamBettingParameterStandings(bettingData[0].group?.id || 1),
  ])

  const simplifiedMatches: LocalizedMatchWithSimplifiedNews[] = matchPredictionOutput.data.map((match) => {
    return {
      match: simplifyMatches(match.match),
      news: simplifyNews(match.news),
    }
  })
  const simplifiedBookmakerBonuses = bookmakerBonuses.map((b) => simplifyBookmakerBonus(b))
  const simplifiedHomeData = simplifyHomeData(homeData)
  const simplifiedBettingData = simplifyHomeData(bettingData)

  const seo: SEO = {
    title: "Calcio.com: Pronostici, Statistiche e Risultati",
    description:
      "Resta aggiornato con i pronostici, le statistiche e i risultati live su tutti i campionati di calcio, dalle leghe nazionali alle competizioni internazionali!",
    keywords: [
      "calcio",
      "News",
      "notizie",
      "Risultati",
      "Diretta scritta",
      "Statistiche",
      "Campionato europeo",
      "Campionato mondiale",
      "Bundesliga (GER)",
      "Inghilterra",
      "Coppa",
      "Italia",
      "Spagna",
      "Francia",
      "Austria",
      "Svizzera",
      "leggende",
      "Giocatori",
      "Squadra",
      "Squadre",
      "Rosa",
    ],
    canonicals: ["/"],
  }

  return {
    props: {
      bookmakerBonuses: simplifiedBookmakerBonuses,
      predictions: simplifiedMatches,
      homeData: simplifiedHomeData,
      bettingData: simplifiedBettingData,
      seo,
      teamParameterStandings,
      navigationProps,
      matches,
      teamStandings,
    },
    revalidate: NextConstants.REVALIDATE,
  }
}
